import { register } from '@netcentric/component-loader';
import { DYNAMIC_LIST_RENDERED } from "commons/constants/events";
import config from './product-list-carousel.config';

class ProductListCarousel {
  constructor(el, params) {
    this.el = el;
    this.config = {...config, ...params};
    this.selectors = config.selectors;
    this.classes = config.classes;
    if (this.config.isCarouselDynamic) {
      this.beforeInit();
    } else {
      this.init();
    }
  }

  beforeInit() {
    window.PubSub.subscribe(DYNAMIC_LIST_RENDERED, () => {
      this.init();
    });
  }

  init() {
    this.setRefs();
    this.setEventListeners();
    this.toggleButtonsState();
  }

  setRefs() {
    this.listContainer = this.el.querySelector(this.selectors.listContainer);
    this.listItems = this.el.querySelector(this.selectors.listItems);
    this.listItemSlides = this.el.querySelectorAll(this.selectors.listItem);
    if (this.listItems === null) {
      // eslint-disable-next-line no-console
      console.error('List component is not configured: no items to display!');
      return;
    }
    this.isCarouselEnabled = this.el.classList.contains(this.classes.isCarouselEnabled);
    this.isInfiniteScrollEnabled = this.el.classList.contains(this.classes.isInfiniteScrollEnabled);
    this.carouselPrevButton = this.el.querySelector(this.selectors.carouselPrevButton);
    this.carouselNextButton = this.el.querySelector(this.selectors.carouselNextButton);
  }

  setEventListeners() {
    if (this.isCarouselEnabled && this.carouselPrevButton) {
      this.carouselPrevButton.addEventListener('click', this.onCarouselPrevClick.bind(this));
    }

    if (this.isCarouselEnabled && this.carouselNextButton) {
      this.carouselNextButton.addEventListener('click', this.onCarouselNextClick.bind(this));
    }

    const event = 'onscrollend' in window ? 'scrollend' : 'scroll';
    this.listItems.addEventListener(event, this.toggleButtonsState.bind(this));
  }

  getCarouselItemWidth() {
    return this.listItemSlides.item(0).clientWidth + 32;
  }

  onCarouselPrevClick() {
    const itemWidth = this.getCarouselItemWidth();

    // Infinite Loop is enabled
    if (this.isInfiniteScrollEnabled) {
        const firstItem = this.listItems.firstElementChild;
        const lastItem = this.listItems.lastElementChild;

        this.listItems.insertBefore(lastItem, firstItem);
        this.listItems.style.transition = 'none';
        this.listItems.scrollLeft -= itemWidth;
        this.listItems.style.transition = 'scroll-left 0.3s ease';
    } else {
        this.listItems.scrollBy({
            top: 0,
            left: -itemWidth,
            behavior: 'smooth',
        });
    }
  }

  onCarouselNextClick() {
      const itemWidth = this.getCarouselItemWidth();
      // Infinite Scroll is enabled
      if (this.isInfiniteScrollEnabled) {
          const firstItem = this.listItems.firstElementChild;

          this.listItems.appendChild(firstItem);
          this.listItems.style.transition = 'none';
          this.listItems.scrollLeft += itemWidth;
          this.listItems.style.transition = 'scroll-left 0.3s ease';
      } else {
          this.listItems.scrollBy({
              top: 0,
              left: itemWidth,
              behavior: 'smooth',
          });
      }
  }

  toggleButtonsState() {
    const disablePrevButton = this.listItems.scrollLeft <= 0;
    const disableNextButton = this.listItems.scrollLeft >= this.listItems.scrollWidth - this.listItems.clientWidth;
    if (this.isCarouselEnabled && !this.isInfiniteScrollEnabled) {
      this.carouselPrevButton.disabled = disablePrevButton;
      this.carouselNextButton.disabled = disableNextButton;
    }
  }
}

register({ ProductListCarousel });
