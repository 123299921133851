export default {
  selectors: {
    listContainer: '.cmp-list__container',
    listItem: '.cmp-list__item-js',
    listItems: '.cmp-list__items-js',
    carouselPrevButton: '.cmp-list__carousel-prev',
    carouselNextButton: '.cmp-list__carousel-next',
  },
  classes: {
    hidden: 'batcom-hide',
    isCarouselEnabled: 'cmp-list__carousel',
    isInfiniteScrollEnabled: 'cmp-infinite-scroll',
  },
  customProps: {},
};
